<template>
  <div id="app">
    <div class="container">
      <!-- HEADER -->
      <div class="row mt-5">
        <div class="col text-center">
          <div
            class="d-flex flex-row justify-content-center align-items-center"
          >
            <div style="flex: 1 1 auto">
              <router-link to="/">
                <img width="100%" src="/images/kamsons-pharmachy-logo.png">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- HEADER (END) -->

      <!-- CONTENT -->
      <router-view />
      <!-- CONTENT (END) -->

      <!-- FOOTER -->
      <footer class="mt-5">
        <div>
          <div class="row">
            <div class="col">
              <div class="text-center documents">
                <!-- <img
                  src="/images/frankie_flu.svg"
                  height="100px"
                  class="mb-3 mt-4"
                /> -->
                <div style="font-size: large">
                  Read our
                  <a href="/downloads/privacy-policy.pdf" target="_blank"
                    >privacy policy</a
                  >
                  and our
                  <a href="/downloads/terms-of-usage.pdf" target="_blank"
                    >terms of use</a
                  >.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="text-center">
                <a href="mailto:flu@kamsons.co.uk">flu@kamsons.co.uk</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- FOOTER (END) -->
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {},
};
</script>
