/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const resend_voucher = /* GraphQL */ `
  query Resend_voucher($email: String!, $code: String!) {
    resend_voucher(email: $email, code: $code) {
      id
      campaign_id
      code
      email
      assigned_timestamp
      sent_timestamp
      resent_timestamp
      pharmacy_used
      pharmacy_used_at
      booking_used
      booking_used_at
      booking_used_source
      active
      employee_number
      post_code
      pharmacy_used_source
      access_code
    }
  }
`;
export const getVerify_campaign_details = /* GraphQL */ `
  query GetVerify_campaign_details($code: String!) {
    getVerify_campaign_details(code: $code) {
      code
      voucher_limit
      registered_count
      organisation_active
      start_date
      end_date
      employee_id
      employee_id_min
      employee_id_max
      employee_id_pattern
    }
  }
`;
export const getCampaign_access_by_code = /* GraphQL */ `
  query GetCampaign_access_by_code($code: String!) {
    getCampaign_access_by_code(code: $code) {
      code
      domain
      auto_domain
      organisation_active
      start_date
      end_date
      employee_id
      employee_id_min
      employee_id_max
      employee_id_pattern
    }
  }
`;
export const getCampaign_access_by_code_voucher_limit_check = /* GraphQL */ `
  query GetCampaign_access_by_code_voucher_limit_check($code: String!) {
    getCampaign_access_by_code_voucher_limit_check(code: $code) {
      voucher_count
      code
      domain
      auto_domain
      organisation_active
      start_date
      end_date
      employee_id
      employee_id_min
      employee_id_max
      employee_id_pattern
    }
  }
`;
export const getCampaign_access_by_auto_domain = /* GraphQL */ `
  query GetCampaign_access_by_auto_domain($domain: String!) {
    getCampaign_access_by_auto_domain(domain: $domain) {
      voucher_count
      code
      domain
      auto_domain
      organisation_active
      start_date
      end_date
      employee_id
      employee_id_min
      employee_id_max
      employee_id_pattern
    }
  }
`;
export const getVoucher_by_email = /* GraphQL */ `
  query GetVoucher_by_email($email: String!) {
    getVoucher_by_email(email: $email) {
      id
      campaign_id
      code
      email
      assigned_timestamp
      sent_timestamp
      resent_timestamp
      pharmacy_used
      pharmacy_used_at
      booking_used
      booking_used_at
      booking_used_source
      active
      employee_number
      post_code
      pharmacy_used_source
      access_code
    }
  }
`;
export const getVoucher_by_email_code = /* GraphQL */ `
  query GetVoucher_by_email_code($code: String!, $email: String!) {
    getVoucher_by_email_code(code: $code, email: $email) {
      id
      campaign_id
      code
      email
      assigned_timestamp
      sent_timestamp
      resent_timestamp
      pharmacy_used
      pharmacy_used_at
      booking_used
      booking_used_at
      booking_used_source
      active
      employee_number
      post_code
      pharmacy_used_source
      access_code
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: Int!) {
    getCampaign(id: $id) {
      id
      start_date
      end_date
      name
      ean13
    }
  }
`;
export const postcode_lookup = /* GraphQL */ `
  query Postcode_lookup($postcode: String!) {
    postcode_lookup(postcode: $postcode) {
      id
      created_at
      postcode
      latitude
      longitude
    }
  }
`;
export const getCampaign_access = /* GraphQL */ `
  query GetCampaign_access($id: Int!) {
    getCampaign_access(id: $id) {
      id
      organisation_id
      campaign_id
      code
      domain
      auto_domain
      voucher_limit
      active
      employee_id
      employee_id_min
      employee_id_max
      employee_id_pattern
      registered_count
      used_count
      used_count_pos
      used_count_booking
      used_count_override
      statistics_updated
      prepaid
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: Int!) {
    getOrganisation(id: $id) {
      id
      name
      active
      created_at
      zoho_id
    }
  }
`;
export const getPostcode = /* GraphQL */ `
  query GetPostcode($id: Int!) {
    getPostcode(id: $id) {
      id
      created_at
      postcode
      latitude
      longitude
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: Int!) {
    getVoucher(id: $id) {
      id
      campaign_id
      code
      email
      assigned_timestamp
      sent_timestamp
      resent_timestamp
      pharmacy_used
      pharmacy_used_at
      booking_used
      booking_used_at
      booking_used_source
      active
      employee_number
      post_code
      pharmacy_used_source
      access_code
    }
  }
`;
